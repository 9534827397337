body {
    padding-top: 50px;
    padding-bottom: 50px;
}

body {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    color: rgb(39, 39, 39);
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
    /* color: rgb(95, 149, 250); */
    color: rgb(114, 114, 114);
}

.name {
    color: rgb(39, 39, 39);
    line-height: 1;
}
.job-title {
    font-size: 1.5rem;
    color: rgb(114, 114, 114);
    font-weight: 500;
}
.name-info {
    display: flex;
    align-items: baseline;
    margin-bottom: 15px;
}

.block-row {
    display: block;
}

.right {
    float: right;
    padding-right: 15px;
}

.description {
    padding: 10px 0 10px 0;
}

.img-container {
    height: 100px;
}
.experience-img {
    height: 50px;
    padding: 8px;
    margin: auto;
    display: block;
}

.exp-card {
    /* -webkit-box-shadow: 0 10px 6px -6px #777;
    -moz-box-shadow: 0 10px 6px -6px #777; */
    box-shadow: 0px 2px 11px rgb(211, 211, 211);
    border-radius: 10px;
    margin-right: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.info {
    padding: 8px;
}

.personal-img {
    width: 220px;
    float: right;
    margin-left: 30px;
}

.project-card {

    max-width: 450px;
    margin: 15px 15px 15px 0px;
    padding: 10px;
    box-shadow: 0px 2px 11px rgb(187, 187, 187);
    border-radius: 10px;
}

.project-img {
    /* width: 450px; */
    max-width:100%;
    max-height:100%;
}

.projects {
    /* display: flex; */
}

.project-tech {
    border-style: solid;
    border-radius: 4px;
    font-size: 12px;
    padding-left: 3px;
    padding-right: 3px;
    margin-left: 3px;
    margin-right: 3px;
    background-color: rgb(77, 77, 77);
    border-color: rgb(77, 77, 77);
    color: white;
}

.header {
    position: fixed;
    top: 0;
    height: 40px;
    background-color: white;
    width: 100%;
    z-index: 10;
}

.header::before {
    content: "";
    background-color: #fff;
    height: 40px;
    position: fixed;
    top: 0;
    width: 100vw;
    left: 0;
    padding: 0 10%;
    z-index: -1;
}

.link {
    padding: 5px;
    color: black;
}

.page-links {
    padding-top: 8px;
    text-align: center;
    display: inline-block;
}

.github {
    color: black;
    margin-right: 10px;
}

.linkedin {
    color: #2867B2;
    margin-right: 10px;
}

.logo {
    height: 30px;
    padding-right: 30px;
    padding-left: 5px;
}

.main {
    padding: 0 10% 0 10%;
}

#about {
    padding-bottom: 40px;
    padding-top: 30px;
}

#experience {
    padding-bottom: 30px;
}

@media only screen and (max-width: 768px) {
    .logo {
        padding-right: 5px;
        height: 25px;
    }

    .personal-img {
        width: 180px;
        float: right;
    }

    .main {
        padding: 0 1% 0 1%;
    }
}